html {
font-size: 62.5%;
}
*{
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'neue-haas-grotesk-display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

P {
  margin: 0px 0px 10px 0px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

*::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 135, 139, 0.8);
}

.rdw-editor-wrapper .rdw-editor-main {
  padding: 0 16px;
}
.rdw-editor-wrapper .rdw-option-wrapper {
  border: none;
  margin: 0 10px;
}
.menu-head-wrapper::-webkit-scrollbar{
  display: block !important;
  width: 4px !important;
  height: 4px !important;
}
.alignItems {
  display:Flex;
  align-Items:center;
}